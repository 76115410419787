import { CircularProgress } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';

import image from '../../banner.jpg';
import ErrorDialog from '../../components/ErrorDialog';
import SpinnerComponent from '../../components/SpinnerComponent';

import theme from '../../theme.js';

const styles = {
    root: {
        height: '100%',
        padding: '4px 10px 20px 10px',
        textAlign: 'center'
    },
    container: {
        border: '1px solid #e17414',
        borderRadius: '6px',
        //margin: '4px 0px 4px 0px',
        padding: '10px',
        margin: 'auto',
        maxWidth: '780px'
    },
    modalcontainer: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',

        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
        marginLeft: '20%',
        marginRight: '20%',
        height: '80%'

    },
}

export default class PagamentoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abbonamentoId: null,
            abbonamentoSospeso: null,
            abbonamentoSospesoPagamento: null,
            loading: true,
            operationInProgress: false,
            errorDialogVisible: false,
            errorDialogMessage: '',
            errorDialogTitle: null,
            fallito: false,
            message: "Nessuna operazione da effettuare.",
            showButtonToStartPaymentProcedure: false,
            showButtonToApprovePayment: false,

        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if ((values.id !== undefined) && (values.id !== null)) {
            const abbonamentoId = values.id;
            this.setState({
                abbonamentoId
            });
            this.getAbbonamentiEsercizio(abbonamentoId);
        }
    }
    handleOpen = () => {
        this.setState({ modal: true });
    };

    handleClose = () => {
        this.setState({ modal: false });
    };
    getAbbonamentiEsercizio = (abbonamentoId) => {

        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;

        const URL = ENDPOINT + '/api/abbonamenti/' + abbonamentoId
        fetch(URL, {
            method: 'GET',

            withCredentials: true,
            credentials: 'include'
        })
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.message);
                }
            })
            .then(result => {
                if (result.stato === 'DISATTIVATO'
                    || result.stato === 'SCADUTO'
                    ) {
                    this.setState({
                        fallito: true,
                        loading: false,
                        abbonamentoSospeso: result
                    }, () => this.prepMessage());
                }
                else {
                    this.setState({
                        loading: false,
                        abbonamentoSospeso: result
                    }, () => this.prepMessage());
                }


            })
            .catch(error => {
                let message = error.message;
                if (message === "Failed to fetch") {
                    message = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                } else {
                    message = "Si è verificato un errore. Caricamento dati fallito.";
                }
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, message);
            });

    }
    getAbbonamentiEsercizioRepeat = (abbonamentoId) => {
        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const URL = ENDPOINT + '/api/abbonamenti/' + abbonamentoId
        fetch(URL, {
            method: 'GET'
        })
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.message);
                }
            })
            .then(result => {
                //alert(result.pagamento.stato)

                this.setState({
                    loading: false,
                    abbonamentoSospeso: result
                }, () => this.prepMessage());




            })
            .catch(error => {
                let message = error.message;
                if (message === "Failed to fetch") {
                    message = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                } else {
                    message = "Si è verificato un errore. Caricamento dati fallito.";
                }
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, message);
            });



    }
    onAvviaProceduraPagamento = () => {
        this.setState({
            operationInProgress: true
        })
        this.startPaymentProcedure(this.state.abbonamentoSospeso.id);
    }

    startPaymentProcedure = (abbonamentoId) => {

        const requestBody = {
            abbonamentoId: abbonamentoId
        };
        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT
        const URL = ENDPOINT + '/api/abbonamenti/pagamenti';
        fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                const status = response.status;
                if (status === 201) {
                    return response.json();
                } else {
                    if (status === 400 || status === 401) {
                        throw new Error("" + status);
                    } else {
                        throw new Error(response.message);
                    }
                }
            })
            .then(result => {

                if (result.stato === 'INSERITO') {
                    this.setState({
                        operationInProgress: false,
                    }, () => {
                        this.getAbbonamentiEsercizioRepeat(abbonamentoId)
                        this.prepMessage()
                    });
                }
                else if (result.stato === 'CREATO') {
                    this.setState({
                        abbonamentoSospesoPagamento: result,
                        operationInProgress: false,

                    }, () => this.prepMessage());
                }
            })
            .catch(error => {
                let msg = error.message;
                let title = null;
                if (msg === "400") {
                    msg = "Creazione nuovo pagamento non permessa: esiste già un pagamento in corso o completato.";
                } else {
                    msg = "Servizio temporaneamente non disponibile. Riprovare più tardi"
                }
                this.setState({
                    loading: false,
                    operationInProgress: false
                });
                this.showErrorDialog(title, msg);
            });

    }

    openApproveLink = (event) => {
        event.preventDefault();
        if (this.state.abbonamentoSospesoPagamento !== null) {
            window.location.href = this.state.abbonamentoSospesoPagamento.paypalApproveLink;

        }
        else {
            window.location.href = this.state.abbonamentoSospeso.pagamento.paypalApproveLink;

        }
    }
    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }
    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    prepMessage = () => {
        let message = this.state.message;
        if (this.state.abbonamentoSospesoPagamento !== null) {
            message = "Clicca sul pulsante per completare il pagamento dell'abbonamento"
                + " al servizio BS-FOOD " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                    + this.state.abbonamentoSospeso.nomeEsercizio : "") + ".";
            this.setState({
                showButtonToApprovePayment: true,
                showButtonToStartPaymentProcedure: false,

                message: message
            })

        }
        else {
            if (this.state.abbonamentoSospeso !== null) {
                if (this.state.abbonamentoSospeso.pagamento === null && this.state.abbonamentoSospeso.error === false) {

                    message = "Avvia la procedura di pagamento dell'abbonamento"
                        + " al servizio BS-FOOD "
                        + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "")
                        + " cliccando sul pulsante qui sotto. "


                    this.setState({
                        showButtonToStartPaymentProcedure: true,
                        showButtonToApprovePayment: false,

                        message: message

                    })
                }
                else if (this.state.abbonamentoSospeso.pagamento === null && this.state.abbonamentoSospeso.error === true) {
                    message = "Tentativo di avvio pagamento fallito. Avviare nuovamente la procedura di pagamento dell'abbonamento"
                        + " al servizio BS-FOOD "
                        + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "")
                        + " cliccando sul pulsante qui sotto. "


                    this.setState({
                        showButtonToStartPaymentProcedure: true,
                        showButtonToApprovePayment: false,

                        message: message

                    })
                } else {
                    if (this.state.abbonamentoSospeso.pagamento.stato === 'CREATO') {

                        message = "Clicca sul pulsante per completare il pagamento dell'abbonamento"
                            + " al servizio BS-FOOD " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                                + this.state.abbonamentoSospeso.nomeEsercizio : "") + ".";
                        this.setState({
                            showButtonToApprovePayment: true,
                            showButtonToStartPaymentProcedure: false,

                            message: message

                        })

                    }
                    else if (this.state.abbonamentoSospeso.pagamento.stato === 'INSERITO'
                    ) {

                        message = "Il link del pagamento per l' abbonamento " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "") + " è in elaborazione.";
                        this.setState({
                            message: message,
                            showButtonToStartPaymentProcedure: false,
                            showButtonToApprovePayment: false,


                        })
                        setTimeout(() => this.getAbbonamentiEsercizioRepeat(this.state.abbonamentoId), 6000)
                    }
                    else if (this.state.abbonamentoSospeso.pagamento.stato === 'APPROVATO'
                        || this.state.abbonamentoSospeso.pagamento.stato === 'PENDENTE') {

                        message = "Il pagamento dell'abbonamento " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "") + " è in elaborazione.";
                        this.setState({
                            message: message,
                            showButtonToStartPaymentProcedure: false,
                            showButtonToApprovePayment: false,


                        })
                    }
                    else if (this.state.abbonamentoSospeso.pagamento.stato === 'FALLITO'
                        || this.state.abbonamentoSospeso.pagamento.stato === 'NEGATO') {

                        message = "Il pagamento dell'abbonamento " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "") + " è fallito.";
                        this.setState({
                            message: message,
                            showButtonToStartPaymentProcedure: false,
                            showButtonToApprovePayment: false,

                        })
                    } else if (this.state.abbonamentoSospeso.pagamento.stato === 'COMPLETATO') {

                        message = "Il pagamento dell'abbonamento " + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                            + this.state.abbonamentoSospeso.nomeEsercizio : "") + " è stato completato con successo.";
                        this.setState({
                            message: message,
                            showButtonToStartPaymentProcedure: false,
                            showButtonToApprovePayment: false,

                        })
                    }
                }
            }
            if (this.state.operationInProgress) {
                message = "Elaborazione in corso del pagamento del canone annuale per "
                    + (this.state.abbonamentoSospeso.nomeEsercizio !== null ? "per l'esercizio commerciale "
                        + this.state.abbonamentoSospeso.nomeEsercizio : "")
                    + this.state.abbonamentoSospeso.nomeEsercizio + ".";
                this.setState({
                    message: message

                })
            }
        }

    }
    render() {
        const logo = image;
        let showButtonToStartPaymentProcedure = this.state.showButtonToStartPaymentProcedure;
        let showButtonToApprovePayment = this.state.showButtonToApprovePayment;

        return (
            <Fragment>
                <CssBaseline />
                <Container style={styles.root}>

                    {
                        this.state.loading ?
                            <SpinnerComponent size={32} />
                            :
                            <Fragment>
                                <Typography component="div" style={{ minHeight: '272px', padding: '4px 0px 4px 0px' }} >
                                    <img src={logo} alt='logo' style={{ width: '270px' }} />
                                </Typography>
                                <Container style={styles.container} >
                                    {!showButtonToApprovePayment && this.state.operationInProgress ?

                                        <CircularProgress />
                                        : null
                                    }
                                    <Typography component="div" >
                                        <Typography
                                            variant="body1"
                                            style={{ textAlign: 'left', padding: '10px', color: 'black' }}
                                        >
                                            {this.state.message}
                                        </Typography>
                                    </Typography>
                                    {showButtonToStartPaymentProcedure && !this.state.operationInProgress && this.state.fallito !== true ?

                                        <Button
                                            type="button"
                                            onClick={this.onAvviaProceduraPagamento}
                                            variant="contained"
                                            size="medium"
                                            style={{
                                                color: theme.palette.secondary.main,
                                                margin: '10px',
                                                backgroundColor: theme.palette.primary.main,
                                            }}
                                        >
                                            AVVIA PROCEDURA PAGAMENTO
                                    </Button>
                                        : null
                                    }
                                    {showButtonToApprovePayment && !this.state.operationInProgress && this.state.fallito !== true ?
                                        <Button
                                            type="button"
                                            onClick={this.openApproveLink}
                                            variant="contained"
                                            size="medium"
                                            style={{
                                                color: theme.palette.secondary.main,
                                                margin: '10px',
                                                backgroundColor: theme.palette.primary.main,
                                            }}
                                        >
                                            VAI ALLA PAGINA DEL PAGAMENTO
                                    </Button>
                                        : null
                                    }
                                </Container>
                            </Fragment>
                    }
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </Container>
            </Fragment>
        );
    }

}
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import theme from '../../theme.js';

import banner from '../../banner.jpg';
import logo_cat from '../../cat_logo.jpg';
import { retrieveToken, setBrandId } from '../../utils/storage.js';
import { getCurrentBrand } from '../../utils/api/brand_api';

const styles = {
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',
        border: 0,
        color: theme.palette.secondary.main,
        height: 54,
        width: 104,
        fontSize: 16,
        fontWeight: 'bold',
    },
    controlpanelButton: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',
        border: 0,
        color: theme.palette.secondary.main,
        height: 68,
        width: 234,
        fontSize: 16,
        fontWeight: 'bold',
    },
    logo1: {
        width: '400px'
    },
    logo2: {
        width: '348px'
    }
}

const logo1 = banner;
const logo2 = logo_cat;

export default class HomePage extends Component {

    componentDidMount() {
        getCurrentBrand(0,10,"id")
        .then(result => {
            if (result.length > 0) {
                setBrandId(result[0].id);
            }
        })
        .catch(error => {
            console.log("ERRORE CARICAMENTO BRAND: " + error.message);
        });
    }

    render() {
        const token = retrieveToken();
        let isAuthenticated = false;
        let message= 'Effettua il Login per gestire la piattaforma.';
        if (token !== null) {
            isAuthenticated = true;
            message = 'Clicca sul pulsante per procedere';
        } 
        let accessButton = isAuthenticated ? 
        (<NavLink to="/amministrazione/responsabili_sicurezza" style={{ textDecoration: 'none' }}>
            <Button variant="contained" style={styles.controlpanelButton}>Pannello Controllo</Button>
        </NavLink>) 
        : 
        (<NavLink to="/login" style={{ textDecoration: 'none' }}>
            <Button variant="contained" style={styles.loginButton}>Login</Button>
        </NavLink>);
        return (
            <React.Fragment>
                <CssBaseline />
                <Container id='home_container' fixed >
                    <Typography component="div" style={{ minHeight: '520px' }}>
                        <Typography component="div" style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                            <Typography variant="h4" style={{ padding: '20px', color:theme.palette.primary.main }} >
                                Pannello di controllo di Confcommercio Firenze
                            </Typography>
                            <Typography component="div" style={{ paddingTop: '20px', paddingBottom: '10px' }} >
                                <img src={logo1} alt="logo" style={styles.logo1} />
                            </Typography>
                            <Typography component="div" style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                                <img src={logo2} alt="logo_cat" style={styles.logo2} />
                            </Typography>
                        </Typography>
                        <Typography component="div" style={{ paddingBottom: '34px' }} >
                            <Typography variant="h6" style={{ padding: '10px', color:theme.palette.primary.main  }} >
                                {message}
                            </Typography>
                            <Typography component="div" style={{ padding: '10px' }} >
                                {accessButton}
                            </Typography>
                        </Typography>
                    </Typography>
                </Container>
            </React.Fragment>
        );
    }

}